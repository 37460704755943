/* Mainly article css, in list and view and the article content elements */
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-link {
  color: #61dafb;
}
.disabled-tab {
    pointer-events:none;
    opacity:0.4;
}

/* Article View */
.arty-article-view {
    height: 100%;
    overflow: auto;
}

.article-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.article-wrapper > .arty-article {
    padding-top: 0;
}

/* Article List */
.arty-list-view {
    height: 100%;
    overflow: auto;
}

.article-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.article-list .no-new-items-seperator {
    text-align: center;
    padding: 30px 0px;
    font-weight: bold;
    color: #777;
}
.article-list .no-new-items-seperator i {
    display: block;
    font-size: 22px;
    margin-bottom: 8px;
}
.article-list .loading-msg {
    padding: 10px 15px;
}

.article-list .infinite-scroll-component__outerdiv {
    overflow-y: hidden;
    width: 100%;
    max-width: 600px;
}

.article-list article:first-child {
    padding-top: 0;
}
.article-list article:last-child {
    padding-bottom: 40px;
}

/* Article Poster */
.arty-article .poster {
    margin: 0 -15px;
    display: flex;
    position: relative;
    border: 0;
    border-top: 7px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #69cdd7, #006db2);
}
.arty-article .poster.decision {
    border-image-source: linear-gradient(to left, #F2765C, #5e314c);
}
.arty-article .poster.protocol {
    border-image-source: linear-gradient(to left, #39DC97, #24615D);
}

.poster .article-type {
    color: #006db2;
    padding: 10px;
    font-size: 12px;
}
.poster.decision .article-type {
    color: #5e314c;
}
.poster.protocol .article-type {
    color: #24615D;
}

/* Article */
.arty-article {
    max-width: 600px;
    margin: 0 10px;
}

.arty-article .content {
    padding: 0 15px 70px;
    background: white;
    border-radius: 5px;
    margin-top: 20px;
}

.arty-article h2 {
    margin: 10px 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
}
.arty-article h2 a {
    text-decoration: none;
    color: #000;
}
/* .arty-article h2.expanded a {
    color: #3498db;
} */

.arty-article p {
    font-size: 13px;
    line-height: 1.8;
    margin-top: 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    letter-spacing: 0.4px;
    /* overflow: hidden;
    max-height: 89px; */
 }
.arty-article p a {
    color: #000;
    text-decoration: none;
}
/* .article-list .arty-article p.expanded {
    max-height: initial;
} 
.article-list .arty-article p.expanded a {
    color: #3498db;
    text-decoration: underline;
}*/

/* 
.arty-article .expand-button {
    padding: 0;
    color: #3498db;
    text-decoration: underline;
    position: relative;
    top: -8px;
    border: none;
    background-color: transparent;
} */

.arty-article .article-link {
    float: right;
    text-align: right;
    font-size: 12px;
    background: #000;
    color: #fff;
    padding: 7px 16px 6px;
    border-radius: 50px;
    margin-top: 5px;
    text-decoration: none;
    transition: all 0.1s ease;
}
.article-link:hover,
.article-link:active {
    background: #3498db;
}

.arty-article .read-more {
    float: left;
    text-align: right;
    font-size: 12px;
    font-weight: bold;
    color: rgb(63, 63, 63);
    padding: 7px 16px 6px 0;
    border-radius: 50px;
    margin-top: 5px;
    text-decoration: none;
    cursor: default;
}
.read-more:hover,
.read-more:active {
    color: rgb(116, 116, 116);;
}

@media only screen and (min-width: 600px) {

    .arty-article .poster {
        margin: 0 -20px 0;
    }

    .arty-article .content {
        padding: 0 20px 70px;
    }
  
    .arty-article p {
        font-size: 14px;
        letter-spacing: 0.6px;
     }
}

/* Article Meta */
.arty-article .article-meta {
    display: flex;
    font-size: 10px;
    color: #777;
    list-style: none;
    margin: 0 0 8px 0;
    padding: 0;
}
.arty-article .article-meta > * {
    padding: 0;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

/* Article Actions */
.arty-article .article-actions {
    display: flex;
    flex-wrap: wrap;
}
.arty-article .article-actions > * {
    margin-right: 18px;
    color: #000;
    transition: all 0.1s ease;
}
.arty-article .article-actions > *:hover,
.arty-article .article-actions > *.active {
    color:#3498db;
    cursor: pointer;
}

.arty-article .article-actions .external-link {
    display: flex;
    text-decoration: none;
    align-items: flex-end;
    padding: 0;
    font-size: 22px;
    border: none;
    background: none;
    outline: none;
    margin-bottom: 0;
}

.arty-article .thumbs-button {
    padding: 0;
    font-size: 22px;
    border: none;
    background: none;
    outline: none;
}
.arty-article .thumbs-button.neg.active {
    color: #e74c3c;
}

.arty-article .share-button {
    display: none;
}
.arty-article .share-button + label {
    font-size: 22px;
}
.arty-article .share-button:checked + label {
    color: #3498db;
}

.arty-article .share-menu {
    position: relative;
    height: 0;
    background-color: #eee;
    overflow: hidden;
    margin: 10px -20px 0 -20px;
    transition: all .3s ease-out;
    width: calc(100% + 40px);
}
.arty-article .share-button:checked ~ .share-menu {
    height: 64px;
    margin-bottom: 10px;
}

.arty-article .share-menu .button-group {
    display: flex;
    position: absolute;
    list-style: none;
    margin: 0;
    bottom: 0;
    padding-left: 20px;
}
.arty-article .share-menu .button-group > * {
    height: 100%;
    font-size: 32px;
    margin-right: 15px;
    margin-bottom: 12px;
    border: none;
    background: none;
    text-decoration: none;
    color: #000;
    padding: 0;
    transition: all 0.1s ease;
}
.arty-article .share-menu .button-group > *:hover {
    color: #3498db;
    cursor: pointer;
}

/* Twitter */
.article-list article.arty-twitter-post:first-child {
    padding-top: 15px;
    margin-top: 10px;
}
.arty-twitter-post {
    position: relative;
    border: 1px solid #E1E8ED;
    background: #fff;
    padding: 15px;
    margin: 0 10px 15px;
}
.arty-twitter-post:before {
    content: "";
    background-image: url(/images/Twitter_Logo_Blue.svg);
    position: absolute;
    right: 12px;
    top: 16px;
    width: 48px;
    height: 48px;
}
.arty-twitter-post:after {
    content: attr(data-date);
    position: absolute;
    right: 12px;
    bottom: 12px;
    width: 140px;
    height: 20px;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
}

.arty-twitter-post .inner {
    height: 48px;
}

.arty-twitter-post a {
    color: #1DA1F2;
}

.arty-twitter-post h3 {
    margin: 0;
    font-size: 16px;
    position: relative;
    top: 4px;
}
.arty-twitter-post h3 > a {
    font-weight: normal;
    display: block;
    margin-top: 2px;
    color: #000;
    text-decoration: none;
}

.arty-twitter-post h3 > a:hover {
    text-decoration: underline;
    color: #1DA1F2;
}

.arty-twitter-post img {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    float: left;
    margin-right: 12px;
}

.arty-twitter-post p {
    line-height: 1.4;
    font-size: 18px;
    margin-bottom: 28px;
}

.circular-progress-div {
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}