/* Dialog */
.arty-notif-dialog {
    position: fixed;
    padding: 30px;
    background-color: rgba(0, 0, 0, .7);
    border-radius: 8px;
    color: #fff;
    text-align: center;
    z-index: 999;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}