/* Highlights Reel */
.arty-promote-list {
    display: flex;
    overflow-x: auto;
    align-items: center;
    width: 100%;
    padding: 10px 10px 0;
    background: #fff;
    box-shadow: 0 2px 18px -2px rgb(0 0 0 / 20%);
}

.arty-promote-list h4 {
    text-align: center;
    font-size: 13px;
    padding: 0 40px;
    color: #777;
}

.arty-promote-list .inner {
    display: flex;
    margin: 0 auto;
    padding: 0 0 10px;
}

.arty-promote-list .promoted-article {
    margin-right: 10px;
    text-decoration: none;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/bernard-hermant-XyzPOIMqWfc-unsplash.jpg) #003759;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 160px;
    padding: 10px 10px 15px;
    display: block;
    min-width: 160px;
    min-height: 150px;
    transition: all 0.3s ease;
    color: #fff;
    /* filter: saturate(1.5);
    text-shadow: 0 1px black; */
    border-radius: 3px;
}
.arty-promote-list .promoted-article:last-child {
    margin-right: 0px;
}
.arty-promote-list .promoted-article:nth-child(even) {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/richard-horvath-cPccYbPrF-A-unsplash.jpg) #003759;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.arty-promote-list .promoted-article.decision {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/stephen-johnson-Q8_-Cc-IXRY-unsplash.jpg) #5e314c;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.arty-promote-list .promoted-article.decision:nth-child(even) {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/bilal-o-A6_3spmvln0-unsplash.jpg) #5e314c;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.arty-promote-list .promoted-article:hover,
.arty-promote-list .promoted-article.decision:hover {
    background: #000;
    background-position: center;
}

.arty-promote-list .promoted-article .date {
    font-size: 11px;
}

.arty-promote-list .promoted-article h3 {
    font-size: 14px;
    font-weight: bold;
    margin: 3px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media only screen and (min-width: 600px) {
    .arty-promote-list .promoted-article {
        width: 185px;
        min-width: 185px;
    }
    .arty-promote-list .promoted-article h3 {
        font-size: 13px;
    }
}