.arty-policy-view {
    padding: 0;
    overflow: hidden;
    margin: 0;
    height: 100%;
    background-color: #fcfcfc;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #333;
}

/* commented out because interfering with Social/Twitter feed   */
  /* .infinite-scroll-component__outerdiv {
    width: 100vw;
}

.infinite-scroll-component {
    width: 100vw;
}  */

.arty-policy-view .article-list-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 500px;
    padding-top: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.letter-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 90vw;
    margin-bottom: 60px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.article-list-container {
    width: 80vw;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
}

.lettercontainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 80px;
    padding: 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
}

.lettercontainer .letter {
    font-family: "DM Sans", sans-serif;
    font-size: 45px;
    line-height: 50px;
    font-weight: 700;
}

.letterlink {
    color: #686868;
    padding: 5px;
    -webkit-transition: color 200ms ease;
    transition: color 200ms ease;
    /* font-size: 30px;
    line-height: 30px; */
    text-decoration: none;
    text-decoration: none;
}

.letterlink:hover {
    color: #000;
  }

.letternav {
    transition: transform 200ms ease, -webkit-transform 200ms ease;
    font-family: "DM Sans", sans-serif;
    font-size: 32px;
    line-height: 34px;
    font-weight: 700;
    text-decoration: none;
}
.letternav:hover {
    transform: scale(1.3);
    transition: transform 200ms ease, -webkit-transform 200ms ease;
}

.policy-title {
    display: flex;
    min-height: 80px;
    padding: 30px;
    border-bottom: 1px solid #ebebeb;
    color: #000;
    text-decoration: none;
    justify-content: space-between;
    transition: background-color 300ms ease;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -webkit-transition: background-color 300ms ease;
    -ms-flex-pack: justify;
}

.policy-title:hover {
    background-color: #f9f9f9;
}

.policy-title:hover #article-arrow {
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    transform: translate3d(20px, 5px, 0px);
    opacity: 1;
    transform-style: preserve-3d;
}

#article-arrow {
    display: flex;
    align-items: center;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    transform: translate3d(0px, 5px, 0px);
    opacity: 0;
    transform-style: preserve-3d;
}

@media screen and (max-width: 991px) {
    .policy-title div {
        font-size: 22px;
    }

    .letternav {
        font-size: 24px;
    }
}


@media screen and (max-width: 767px) {

    .arty-list-wrapper {
      min-height: 60px;
      padding: 20px;
    }

    .h1.heading {
        font-size: 50px;
        line-height: 50px;
    }

    .letter-wrapper {
        display: none;
      }
  
    .policy-title div {
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
    }  
}  

@media screen and (max-width: 479px) {
    .arty-policy-view {
        font-size: 12px;
        line-height: 16px;
    }

    .h1.heading {
        font-size: 36px;
        line-height: 36px;
    }

    .letter-wrapper {
        display: none;
    }

    .lettercontainer .letter {
        font-size: 24px;
        font-weight: 700;
        line-height: 16px;
    }
    
    .article-list-container {
        width: 80vw;
        font-family: "DM Sans", sans-serif;
        font-size: 18px;
    }

    .policy-title div {
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
    }
}   