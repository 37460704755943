/* App layout framework */

.shell {
    height: 100vh !important;
}

/* Header */
.shell-header {
    color: #fff;
    background-color: #222;
    position: fixed;
    width: 100vw;
    z-index: 2;
}

.shell-header ul {
    display: flex;
    justify-content: space-between;
    height: 40px;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    max-width: 1024px;
}

/* .shell-header .middle-column {
    flex-grow: 2;
    text-align: center;
} */

.shell-header a {
    color: #fff;
    text-decoration: none;
    line-height: 40px;
    padding: 0 15px;
}
.shell-header a:hover {
    color: #3498db;
}

.shell-header a span.user-settings {
    font-size: 11px;
}

/* Content */
.shell-content {
    height: calc(100vh - 40px);
    padding-top: 40px !important;
}

/* Footer */
.shell-footer {
    background-color: #222;
    padding-bottom: 10px;
    position: fixed;
    bottom: 0;
    width: 100vw;
}

.shell-footer ul {
    display: flex;
    justify-content: space-between;
    height: 50px;
    list-style: none;
    margin: 0 auto;
    max-width: 1024px;
    padding-left: 0;
}

.shell-footer li {
    text-align: center;
}

.shell-footer li a {
    display: block;
    color: #fff;
    text-decoration: none;
    padding: 12px 15px 0;
    height: 100%;
    box-sizing: border-box;
}

.shell-footer li span {
    display: block;
    font-size: 11px;
    line-height: 1;
}

.shell-footer li a:hover,
.shell-footer li a.active {
    color: #3498db;
}

.shell-footer li a i {
    margin-bottom: 4px;
}
