.protocol-title {
    display: block;
    font-size: 14px;
    padding-bottom: 8px;
}
.protocol-headers {
    margin-top: 8px;
    font-weight: bold;
    font-size: 20px;
}
.protocol-date {
    display: block;
    padding-top: 4px;
    font-size: 14px;
}

.show-more-button-hidden {
    margin-right: 4px;
}
.hide-excerpts {
    display: none;
}
.show-excerpts {
    display: inline-block;
    width: 100%;
}
.excerpts {
    padding-bottom: -24px;
}
.excerpt-container {
    cursor: pointer;
    margin-top: 24px;
}
.excerpt-other {
    font-weight: bold;
    font-size: 14px;
}

.highlighttext {
    background-color: yellow;
}

.excerpt-text {
    margin: 8px;
    font-family: monospace;
    font-size: 14px;
    line-height: 1.5;
    padding-top: 8px;
}
.excerpt-container em {
    font-style: italic;
    text-decoration: underline;
}
.page {
    float: right;
    padding: 4px;
    font-size: 12px;
    font-weight: bold;
}
