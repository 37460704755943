.login-page-health {
    background-color: #FFFFFF !important; 
}
.login-page-policy {
    background-color: #FFFFFF !important;
}


/* importent key word needed to overwrite MUI CSS */
.health-button {
    background-color: #67B8b7 !important;
    color: #FFFFFF !important;
    margin-left: -25px !important;
    padding: 20px !important;
    border-radius: 0px !important;
}

.policy-button {
    background-color: #F2765C !important;
    color: #FFFFFF !important;
    padding: 20px !important;
    border-radius: 0px !important;
}

.center {
    align-items: center;
    justify-content: center;
    display: flex;
}

.login-nav-bar {
    position: fixed;
}

.login-page-health form {
    align-items: center;
    display: flex;
    width: 600px;
    flex-direction: column;  
}

.login-page-policy form {
    align-items: center;
    display: flex;
    width: 600px;
    flex-direction: column;
}

.login-page-health form input {
    width: 80%;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 8px;
    line-height: 2.2;
    text-indent: 3px;
    margin: 10px;
    background-color: #67B8b7;
    padding: 10px 25px;
    font-size: 16px;
}

.login-page-policy form input {
    width: 80%;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 8px;
    line-height: 2.2;
    text-indent: 3px;
    margin: 10px;
    background-color: #F2765C !important; 
    padding: 10px 25px;
    font-size: 16px;
}

.login-page-health form button {
    margin: 10px;
    margin-bottom: 30px;
    padding: 15px 20px;
    font-size: 20px;
    border-radius: 8px;
    color: #67B8b7;
    text-decoration: none;
    opacity: 1;
    transition: opacity .23s ease;
    cursor: pointer;
    display: block;
    width: 80%;
    border-color: #67B8b7;
    text-align: left;
    background-color: #FFFFFF;
    border-style: solid;
    
}

.login-page-policy form button {
    margin: 10px;
    margin-bottom: 30px;
    border-radius: 8px;
    color: #F2765C;
    padding: 15px 20px;
    font-size: 20px;
    text-decoration: none;
    opacity: 1;
    transition: opacity .23s ease;
    cursor: pointer;
    display: block;
    width: 80%;
    border-color: #F2765C;
    text-align: left;
    background-color: #FFFFFF;
    border-style: solid;
}

::placeholder {
    color: #FFFFFF;
}


@media screen and (max-width: 900px) {

    .form {
        width: auto !important;
        height: 100vh;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 60px 20px;
        margin: auto;
    }
        .health-button {
            margin-left: -20px !important;
        }
}

@media screen and (max-width: 400px) {

        .input {
            padding: 5px 10px;
            font-size: 15px !important;
        }
    
        .button {
            padding: 15px 10px;
            font-size: 15px !important;
            text-indent: 20px;
        }
        .grey {
            font-size: 12px !important;
        }

        .text {
            font-size: 10px !important;            
        }

}   

.background-image {
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

.grey-text {
    display: flex;
    margin: 20px;
    margin-top: 10px;
    font-family: 'DM Sans',
    sans-serif;
    color: #c7c7c7;
    text-align: center;
    font-size: 12px !important;
}

.grey-button {
    color: #8f8f8f!important ; 
    text-decoration: underline  !important;
    margin: 0 !important;
    padding: 5px 10px !important;
    font-size: 10px !important;
} 
