.filter-input input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 3px;
    line-height: 2.0;
    text-indent: 3px;
    margin-bottom: 2px;
}

.checkbox-list {
    animation: fadeInAnimation ease 2s;
    animation-fill-mode: forwards;
}
  
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
