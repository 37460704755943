/* -- Navbar -- */

.policy-navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 120px;
    margin-top: 0px;
    padding-right: 60px;
    padding-left: 60px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    background-color: #fff;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-direction: row;
    -ms-flex-pack: center;
    -ms-flex-align: center;
}

.policy-navbar .nav-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex-pack: justify;
    -ms-flex-align: center;
    -ms-flex: 1;
}

.policy-navbar-divider {
    border-color: #535353;
    width: 80%;
    margin: 20px auto;
}

/* -- Icons -- */

.nav-icon-1 {
    display: inline-block;
    position: relative;
    max-width: 100%;
    z-index: 50;
    width: 150px;
}

.nav-icon-1:hover {
    opacity: 0.65;
}

.nav-icon-2 {
    transition: opacity 325ms ease;
    transition: transform 200ms ease, -webkit-transform 200ms ease;
}

.nav-icon-2:hover {
    transform: scale(1.1);
    transition: transform 200ms ease, -webkit-transform 200ms ease;
    opacity: 0.8;
}
/* -- Drawer -- */

.nav-menu {
    color: #fff;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: 0%;
    min-width: 270px;
    margin-right: 0px;
    padding-top: 117px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #292929;
    min-height: 100vh;
}

.nav-menu .list-item {
    color: inherit;
    padding-left: 12px;
    font-size: 14px;
    line-height: 20px;
}

.policy-list-item-text {
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
}
