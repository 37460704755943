.protocol-plain-search {
    position: fixed;
    display: flex;
    flex-direction: row;
    background: #fff;
    z-index: 1;
    padding: 3vh;
    justify-content: center;
}

.protocol-filter {
    position: sticky;
    top: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    z-index: 1;
    padding: 16px;
    margin-top: auto !important;
    margin-left: initial !important;
    background: linear-gradient(to top, #5dc6c9 5%, white 75%) no-repeat;
    background-size: auto 80vh;
    width: 100%;
}

.protocol-filter-inner {
    display: flex;
    width: 100%;
    max-width: 854px;
    flex-direction: row;
    align-items: center;
}

.protocol-filter-inner.plain {
    height: 90px;
}

.protocol-filter-inner.plain .protocol-filter-search-wrapper {
    margin-left: 135px;
    margin-bottom: 8px;
}

.protocol-filter-search-wrapper,
.protocol-filter-search {
    flex: 1 1 auto;
}

.protocol-filter .MuiOutlinedInput-root {
    background-color: #FFF;
}

.protocol-filter .search-bar input,
.protocol-filter .date-picker input,
.protocol-filter .MuiSelect-select {
    padding: 8px 10px !important;
}

.protocol-filter + div .arty-article:first-child .content {
    margin-top: 0;
}

.protocol-filter-upper > * {
    width: calc((100% - 32px) / 3);
    max-width: 277.33px;
}

.protocol-filter-lower {
    margin-top: 12px !important;
}

.protocol-filter-lower > .search-bar {
    width: calc((100% / 3) * 2);
}

.protocol-filter-lower > .sort-filter {
    width: calc(((100% - 16px) / 3)) !important;
}

.protocol-filter .MuiFormControl-root + .MuiFormControl-root {
    margin-left: 16px;
}

.protocol-filter .source-filter label:not(.MuiFormLabel-filled):not(.Mui-focused),
.protocol-filter .search-bar label:not(.MuiFormLabel-filled):not(.Mui-focused)  {
    transform: translate(8px, 10px) scale(1);
}

.protocol-filter-saver {
    width: 120px;
    padding: 8px;
    margin-left: 16px;
    background: linear-gradient(to top, #5dc5c900 25%, #5dc6c9 75%) no-repeat;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.coverage-filter-saver {
    width: 120px;
    padding: 8px;
    margin-top: auto;
    margin-left: 16px;
    background: linear-gradient(to top, #5dc5c900 25%, #5dc6c9 75%) no-repeat;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.coverage-filter-saver-advanced {
    width: 120px;
    padding: 8px;

    margin-left: 16px;
    background: linear-gradient(to top, #5dc5c900 25%, #5dc6c9 75%) no-repeat;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.coverage-filter-saver-advanced button {
    font-size: 10px;
    color: #808080;
    font-weight: 800;
    line-height: normal;
    text-align: center;
    padding: 4px 6px;
    background-color: #FFFFFF;
    transition: all 0.1s ease;
    border-radius: 8px;
    border-color: currentColor;
    width: 100%;
}


.coverage-filter-saver button {
    font-size: 10px;
    color: #808080;
    font-weight: 800;
    line-height: normal;
    text-align: center;
    padding: 4px 6px;
    background-color: #FFFFFF;
    transition: all 0.1s ease;
    border-radius: 8px;
    border-color: currentColor;
    width: 100%;
}






.protocol-filter-saver button {
    font-size: 10px;
    color: #808080;
    font-weight: 800;
    line-height: normal;
    text-align: center;
    padding: 4px 6px;
    background-color: #FFFFFF;
    transition: all 0.1s ease;
    border-radius: 8px;
    border-color: currentColor;
    width: 100%;
}

.protocol-filter-saver > * + * {
    margin-top: 12px !important;
}

.protocol-filter-saver button:hover {
    color: #555555;
    border-color: currentColor;
    background-color: #FFFFFF;
}

.infinite-scroll {
    overflow-y: hidden;
    width: 100%;
    max-width: 600px;
}

.infinite-scroll .loading-msg {
    text-align: center;
}

.source-group__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.source-group__heading:hover {
    background: #f3f3f3;
}

.MuiListItemText-root .MuiTypography-root {
    font-size: 14px;
}

@media screen and (min-width:991px) {
    .protocol-filter-saver {
        padding: 8px 12px;
    }
}

@media screen and (max-width:990px) {
    .protocol-filter {
        padding: 10px;
        padding-top: 16px;
    }

    .protocol-filter-saver {
        margin-left: 10px;
    }

    .protocol-filter-saver > * + * {
        margin-top: 10px !important;
    }

    .protocol-filter .MuiFormControl-root + .MuiFormControl-root {
        margin-left: 10px;
    }

    .protocol-filter-upper > * {
        width: calc((100% - 20px) / 3);
        max-width: 277.33px;
    }

    .protocol-filter-lower > .search-bar {
        width: calc((100% / 3) * 2);
    }

    .protocol-filter-lower > .sort-filter {
        width: calc(((100% - 10px) / 3)) !important;
    }
}

@media screen and (max-width:767px) {
    .protocol-filter-lower {
        margin-top: 12px !important;
    }

    .protocol-filter-upper > *,
    .protocol-filter-lower .search-bar {
        flex: 1 1 auto;
    }

    .protocol-filter-upper > .source-filter,
    .protocol-filter-lower > .sort-filter {
        max-width: 88px;
        width: 100% !important;
    }

    .protocol-filter-saver {
        width: 84px;
    }

    .protocol-filter-saver button {
        font-size: 8px;
        padding: 4px 6px;
    }
}

@media screen and (max-width:600px) {
    /*.protocol-filter .date-picker input {*/
    /*    opacity: 0;*/
    /*    margin-left: -28px;*/
    /*    width: 0;*/
    /*}*/
}

/* ADVANCED SEARCH */
.protocol-filter .advanced-search {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 6px 6px 0;
    background-color: #FFFFFF;
    transition: all 0.1s ease;
    border-radius: 8px;
    width: 100%;
}

.protocol-filter .advanced-search .MuiTypography-root {
    font-size: 10px;
    color: #808080;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: -0.5px;
}

@media screen and (max-width:878px) {
    .protocol-filter-inner.plain {
        height: auto
    }

    .protocol-filter-inner.plain .protocol-filter-search-wrapper {
        margin-left: 0;
    }
}
