/* Landing Page */
.landing-page {
    background-color: #5dc6c9;
    min-height: 100vh;
    height: 100%;
}

/* Header */
.landing-page header {
    background-color: #222;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.landing-page header ul {
    display: flex;
    max-width: 1024px;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 15px;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .landing-page header ul {
        padding: 10px 20px;
    }
}

.landing-page header #logo {
    font-size: 20px;
    flex: 1;
}
.landing-page header #logo img {
    vertical-align: middle;
    width: 160px;
}

.landing-page header a {
    background-color: #F2765C;
    border-radius: 50px;
    color: #fff;
    padding: 10px 25px;
    font-size: 16px;
    text-decoration: none;
    opacity: 1;
    transition: opacity .23s ease;
    cursor: pointer;
    display: block;
}
.landing-page header a:hover {
    opacity: .9;
}
@media only screen and (max-width: 600px) {
    .landing-page header #logo img {
        width: 120px;
    }
    .landing-page header a {
        padding: 8px 20px;
        font-size: 14px;
    }
}

/* Hero */
.landing-page #hero {
    display: flex;
    flex-direction: column;
    background-image: url(/illustration.png);
    background-size: contain;
    background-position: center;
    height: 50vw;
    max-height: 550px;
    background-repeat: no-repeat;
}

/* Features */
.landing-page .features {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 30px;
}

.landing-page .features .inner {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 30px;
    max-width: 1024px;
}

@media only screen and (max-width: 600px) {
  .landing-page .features .inner {
      grid-template-columns: 100%;
  }
}

.landing-page .features h2 {
    color: #fff;
    text-align: center;
}

.landing-page .features p {
    line-height: 1.8;
}

/* Footer */
.landing-page footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em 0 3em;
    color: #5e314c;
}

.landing-page footer > ul {
    display: flex;
    list-style: none;
    width: 100%;
    max-width: 1024px;
    margin: 0;
    padding: 0 20px;
}

.landing-page footer > ul li:first-child {
    flex: 1;
}
.landing-page footer ul li {
    font-size: 14px;
    text-align: center;
}

.landing-page footer > ul li > ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
.landing-page footer > ul li > ul li {
    margin-left: 8px;
}