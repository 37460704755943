.policy-details-view {
    background-color: #fcfcfc;
    height: 100%;
    min-height: 100vh;
}

.policy-details-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    width: 60vw;
    margin-right: auto;
    margin-left: auto;
    font-family: "DN-Sans", sans-serif;
    text-align: center;
    padding-bottom: 60px;
}

.policy-details-container {
    font-size: 19px;
    font-family: Arial, sans-serif;
    color: #5e5e5e;
    line-height: 26px;
    text-align: left;
    width: 60vw;
    min-height: 500px;
    margin-right: auto;
    margin-left: auto;
}

.policy-details-text {
    align-items: center;
    font-family: Arial, sans-serif;
    color: #5e5e5e;
    font-size: 18px;
    line-height: 26px;
}

.policy-details-more {
    font-family: Arial, sans-serif;
    color: #5e5e5e;
    font-size: 18px;
    line-height: 26px;
    text-decoration: underline;
}

.policy-details-page-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.policy-tab-button {
    text-transform: none !important;
    margin-left: 20px;
    padding-left: 20px;
    border: 1px solid #ffeaea !important;
    border-radius: 5px;
    color: #222222 !important;
    background-color: transparent;
    transition: border-color 400ms ease;
}

h1.heading {
    font-family: "DM Sans", sans-serif;
    font-size: 55px;
    line-height: 60px;
    font-weight: 700 !important;
    text-align: center;
}

.tabs-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 45px;
    margin-bottom: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.button-text {
    margin-left: 3px;
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
}

.send-as-mail-button {
    float: left;
    align-items: left;
    text-transform: none !important;
    border: 1px solid #ffeaea;
    border-radius: 5px;
    background-color: transparent;
    transition: border-color 400ms ease;
}

.policy-page-divider {
    width: 100%;
    border: 1px solid #ebebeb !important;
    margin-top: 38px !important;
    margin-bottom: 20px !important;
}

@media screen and (max-width: 767px) {

    h1.heading {
        font-size: 45px;
        line-height: 45px;
    }
    
    .policy-details-container {
        width: 70vw;
    }

    .policy-details-text {
        font-size: 16px;
    }

    .policy-details-more {
        font-size: 16px;
    }
}  

@media screen and (max-width: 479px) {
    .arty-policy-view {
        font-size: 12px;
        line-height: 16px;
    }
    .policy-details-container {
        width: 80vw;
    }

    h1.heading {
        font-size: 36px;
        line-height: 36px;
    }

    .letter-wrapper {
        display: none;
    }

    .lettercontainer .letter {
        font-size: 24px;
        font-weight: 700;
        line-height: 16px;
    }
    
    .article-list-container {
        width: 80vw;
        font-family: "DM Sans", sans-serif;
        font-size: 18px;
    }

    .policy-title div {
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
    }
}   