/* Settings */
.arty-profile-view {
    display: flex;
    height: 100%;
    overflow: auto;
    flex-direction: column;
    align-items: center;
}

.arty-profile-view .inner {
    padding: 20px 15px;
    max-width: 600px;
}

.arty-profile-view h2 {
    margin: 0 0 30px 0;
}

.arty-profile-view .input-form input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 3px;
    line-height: 2.2;
    text-indent: 3px;
    margin-bottom: 10px;
}

.arty-profile-view .input-form button {
    background: #000;
    border: none;
    color: #fff;
    padding: 7px 16px 6px;
    border-radius: 50px;
    font-size: 12px;
    transition: all 0.1s ease;
    float: right;
    margin-top: 5px;
}
.arty-profile-view .input-form button:hover {
    background: #3498db;
}

.arty-profile-view fieldset {
    margin-bottom: 20px;
    border: none;
    padding: 0;
}

.arty-profile-view .checkbox-list {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 200px;
    overflow: auto;
    padding: 2px;
    border: 1px solid rgba(0,0,0,.3);
    border-radius: 4px;
    background: #fff;
}
.arty-profile-view .checkbox-list li {
    margin-bottom: 6px;
    padding: 0 6px;
}

.arty-profile-view legend {
    margin-bottom: 5px;
}

.highlight-phrase {
    color: black;
    background-color: yellow;
}

.fancy-toggle-switch input[type="checkbox"] {
    display: none;
    visibility: hidden;
}
.fancy-toggle-switch .switcher:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background-color: #000;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    margin-top: -3px;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-right: 8px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
.fancy-toggle-switch .switcher:hover:before,
.fancy-toggle-switch input[type="checkbox"]:checked + .switcher:hover:before {
    background-color: #3498db;
}
.fancy-toggle-switch input[type="checkbox"]:checked + .switcher:before {
    right: 100%;
    margin-right: -24px;
    background-color: #000;
}
.fancy-toggle-switch input[type="checkbox"]:checked + .switcher {
    background-color: #F2765C;
}
.fancy-toggle-switch .switcher {
    display: inline-block;
    border-radius: 100px;
    width: 22px;
    height: 12px;
    background-color: #ccc;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    cursor: pointer;
}

.fancy-toggle-switch .label {
    cursor: pointer;
    vertical-align: middle;
    margin: 0 8px;
    font-size: 13px;
}