/* App-wide base css */
html,
#root {
  width: 100%;
  height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to top, #5dc6c9 5%, white 75%) no-repeat;}

body * {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6,
.secondary-font,
.shell-header, 
.shell-footer,
.article-link,
.date,
.arty-profile-view .input-form button,
legend {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.4;
  font-weight: 600;
}

h2 {
  font-size: 1.4em;
}

code,
.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button {
  display: block;
  text-align: right;
  font-size: 14px;
  margin-bottom: 30px;
}
.button span {
  display: inline-block;
  background: black;
  color: white;
  font-weight: bold;
  padding: 7px 16px 6px;
  border-radius: 50px;
  transition: all 0.2s ease;
}
.button:hover span {
  transform: scale(1.1);
}